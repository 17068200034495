// import React from "react";
import * as d3 from "d3";
import { relative } from "path";
import React from "react";
import Person from "../component/Person";
import Status from "../component/Status";
import VizComments from "../component/VizComments/VizComments";
import { useScrollBounds } from "../lib/useScrollBounds";
import {
  isHovered,
  isHoveredCtry,
  isHoveredIncomeGroup,
  isRelated,
  quintileKey,
  textAnchorAlign,
  xPosCtryAlign,
  yPosCtryAlign
} from "../utils/Helpers";
// import WorldOpportunitiesContent from "./WorldOpportunitiesContent";
// import WorldOpportunitiesShape from "./WorldOpportunitiesShape";

// Opportunities, GDP
// Proportional with circle size
// Inverse proportional with distance
const Dataviz = ({ state, actions }) => {
  const {
    countryData,
    countriesRichToPoor,
    hoveredCountries,
    supportingInfo
  } = state;
  const [
    containerRef,
    { scrollVPos, windowHeight, windowWidth, containerVPos }
  ] = useScrollBounds();

  const heightScale = 10;
  const margin = { top: 24, right: 100, bottom: 0, left: 100 };
  const viewportHeight = windowHeight * heightScale;
  const format = d3.format(",.3r");

  // Style
  const styles = {
    dataviz: {
      position: "static",
      padding: 0,
      margin: 0
    },
    person: {
      fill: "black"
    },
    background: {
      fill: "#263237"
    },
    status: {
      position: "sticky",
      top: "50%",
      paddingLeft: margin.left,
      pointerEvents: "none",
      height: 0,
      width: windowWidth,
      opacity: 0.5
    },
    quintileColorScale: {
      purple: [0, "#C452EB", "#9052E9", "#5B50E5", "#4D73DD", "#499BD1"],
      darker: [
        0,
        "#672B7A",
        "#4B2A79",
        "#2F2A77",
        "#2F2A77",
        "#283B72",
        "#254F6B"
      ]
    },
    greyscale: [
      "#78909c",
      "#607d8b",
      "#546e7a",
      "#455a64",
      "#37474f",
      "#263238"
    ],
    countryAxisContainer: {
      position: "sticky",
      justifyContent: "center",
      top: margin.top,
      height: 0, // FIXME
      width: 0,
      padding: 0,
      margin: 0 // FIXME
    },
    countryLabel: {
      fontSize: "24",
      fontWeight: "bold",
      fill: "white"
    },
    countryYear: {
      fontSize: "18",
      fill: "white"
    },
    worldOpportunities: {
      position: relative
    },
    worldOpportunitiesContent: {
      position: "absolute",
      width: windowWidth,
      textAlign: "center",
      zIndex: 1
      // transform: "translateY(10%)"
    },
    lineGuides: {
      position: "relative",
      left: 0,
      top: 0
    }
  };

  const incomeGroupList = [
    "Richest 20%",
    "Upper Middle 20%",
    "Middle 20%",
    "Lower Middle 20%",
    "Poorest 20%"
  ];

  // scaleOrdinal().domain([0, 1, 2, 3, 4]).range(['q5', 'q4', ...])

  const countryDataSample = countryData.filter(d => d.income_group === "q5");
  const countryDataRTP = countryDataSample.sort(
    (a, b) => b.gdp_share_pc - a.gdp_share_pc
  );

  const gdpPersonRichest = d3.max(countryData, d => d.gdp_share_pc);
  const gdpPersonPoorest = d3.min(countryData, d => d.gdp_share_pc);

  const gdpPerson_Rad = d3
    .scaleLinear()
    .domain([gdpPersonPoorest, gdpPersonRichest])
    .range([3, windowWidth / 6]);

  const gdpPerson_Dist = d3
    .scaleLinear()
    .domain([gdpPersonPoorest, gdpPersonRichest * 1.02])
    .rangeRound([viewportHeight, 0])
    .clamp(true);

  const statusOpacity = d3
    .scaleLinear()
    .domain([
      gdpPerson_Dist(gdpPersonRichest * 1.005),
      gdpPerson_Dist(gdpPersonRichest * 0.98)
    ])
    .range([0, 1])
    .clamp(true);

  const countryScale = d3
    .scaleLinear()
    .domain([countriesRichToPoor.length, 0])
    .rangeRound([margin.left, windowWidth - margin.right]);
  // .rangeRound([margin.left, windowWidth - gdpPerson_Rad(gdpPersonRichest)]);

  // Country dimension labels
  // const countryAxisNames = countryDataRTP.map(d => d.country_code);
  // console.log(countryAxisNames);

  // Voronoi
  const points = countryData.map(d => [
    countryScale(countriesRichToPoor.indexOf(d.country)),
    gdpPerson_Dist(d.gdp_share_pc)
  ]);

  const voronoi = d3.voronoi().extent([[1, 1], [windowWidth, viewportHeight]]);
  const voronoiPolygons = voronoi(points).polygons();
  const lineGenerator = d3.line();

  return (
    <>
      {/* <div className="worldOpportunities" style={styles.worldOpportunities}> */}
      {/* <WorldOpportunitiesContent style={styles.worldOpportunitiesContent} /> */}
      {/* <svg
          style={styles.lineGuides}
          className="lineGuides"
          version="1.1"
          height={(3 * windowWidth) / 4}
          width={windowWidth}
          preserveAspectRatio="xMidYMid"
          xmlns="http://www.w3.org/2000/svg"
        >
          {countriesRichToPoor.map((e, i) => (
            <g key={i} className={`country ${e}`}>
              <line
                className="countryGuide"
                x1={countryScale(countriesRichToPoor.indexOf(e))}
                y1={0}
                x2={countryScale(countriesRichToPoor.indexOf(e))}
                y2={(3 * windowWidth) / 4}
                stroke={styles.greyscale[1]}
                strokeWidth={1}
                opacity={0.05}
              />
            </g>
          ))}
          <WorldOpportunitiesShape
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            scrollVPos={scrollVPos}
            countriesRichToPoor={countriesRichToPoor}
          />
        </svg>
      </div> */}
      <div className="dataviz" style={styles.dataviz}>
        <div
          className={"incomeStatusIndicator"}
          style={{ ...styles.status, opacity: statusOpacity(containerVPos) }}
        >
          {
            <Status
              moneyAmount={gdpPerson_Dist.invert(containerVPos)}
              incomeRichest={gdpPersonRichest}
              pageHeight={viewportHeight}
              pageWidth={windowWidth}
              windowHeight={windowHeight}
              windowWidth={windowWidth}
              gdpPersonPoorest={gdpPersonPoorest}
              gdpPersonRichest={gdpPersonRichest}
            />
          }
        </div>
        <div
          className="countryAxisContainer"
          style={styles.countryAxisContainer}
        >
          {countryDataRTP.map(d => (
            <p
              key={quintileKey(d)}
              className="countryCode"
              style={{
                position: "absolute",
                top: 0,
                left: countryScale(countriesRichToPoor.indexOf(d.country)),
                fontSize: "10px",
                transform: "rotate(-90deg)",
                transformOrigin: "0% 50%"
              }}
            >
              {d.country_code}
            </p>
          ))}
        </div>
        <svg
          ref={containerRef}
          className="shapes"
          version="1.1"
          height={viewportHeight}
          width={windowWidth}
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            className={"backdrop"}
            height={viewportHeight}
            width={windowWidth}
            fill={"transparent"}
          />
          <g className={"incomeAxis"}>
            {[...new Array(6)].map((d, i) => {
              const incomeSlice = (gdpPersonRichest - gdpPersonPoorest) / 5;
              const income = gdpPersonPoorest + incomeSlice * i;
              return (
                <React.Fragment key={i}>
                  {i !== null ? (
                    <>
                      <line
                        x1={0}
                        y1={gdpPerson_Dist(income)}
                        x2={windowWidth}
                        y2={gdpPerson_Dist(income)}
                        stroke={styles.greyscale[i]}
                        opacity={0.2}
                      />
                      <text
                        x={margin.left}
                        y={gdpPerson_Dist(income)}
                        fill={"white"}
                      >
                        {`$${format(income)}`}
                      </text>
                    </>
                  ) : null}
                </React.Fragment>
              );
            })}
          </g>
          {countriesRichToPoor.map((e, i) => (
            <g key={i} className={`country ${e}`}>
              <line
                className="countryGuide"
                x1={countryScale(countriesRichToPoor.indexOf(e))}
                y1={gdpPerson_Dist(
                  countryData.find(
                    d => d.country === e && d.income_group === "q5"
                  ).gdp_share_pc
                )}
                x2={countryScale(countriesRichToPoor.indexOf(e))}
                y2={gdpPerson_Dist(
                  countryData.find(
                    d => d.country === e && d.income_group === "q1"
                  ).gdp_share_pc
                )}
                stroke={styles.greyscale[1]}
                strokeWidth={1}
                opacity={
                  hoveredCountries.length > 0
                    ? isHoveredCtry(
                        hoveredCountries,
                        countryData.find(f => f.country === e)
                      )
                      ? 1
                      : 0
                    : 0
                }
              />
              <line
                className="countryGuide"
                x1={countryScale(countriesRichToPoor.indexOf(e))}
                y1={0}
                x2={countryScale(countriesRichToPoor.indexOf(e))}
                y2={gdpPerson_Dist(
                  countryData.find(
                    d => d.country === e && d.income_group === "q1"
                  ).gdp_share_pc
                )}
                stroke={styles.greyscale[1]}
                strokeWidth={1}
                opacity={0.1}
              />
              {countryData
                .filter(d => d.country === e)
                .map((d, j) => (
                  <React.Fragment key={j}>
                    <Person
                      key={`${d.country} ${i} ${j}`}
                      x={countryScale(countriesRichToPoor.indexOf(d.country))}
                      y={gdpPerson_Dist(d.gdp_share_pc)}
                      rIncome={gdpPerson_Rad(d.gdp_share_pc)}
                      rPerson={2}
                      opacityIncome={
                        hoveredCountries.length > 0
                          ? isHovered(hoveredCountries, d)
                            ? 1
                            : isRelated(hoveredCountries, d)
                            ? 0.85
                            : 0.1
                          : 0.5
                      }
                      opacityLabels={
                        hoveredCountries.length > 0
                          ? isHoveredCtry(hoveredCountries, d)
                            ? 0.5
                            : 0
                          : 0
                      }
                      styles={styles}
                      country={d.country}
                      income={format(d.gdp_share_pc)}
                      incomeGroup={d.income_group}
                      incomeGroupList={incomeGroupList}
                      incomeFill={
                        d.income_group === "q5"
                          ? styles.quintileColorScale.purple[1]
                          : d.income_group === "q4"
                          ? styles.quintileColorScale.purple[2]
                          : d.income_group === "q3"
                          ? styles.quintileColorScale.purple[3]
                          : d.income_group === "q2"
                          ? styles.quintileColorScale.purple[4]
                          : d.income_group === "q1"
                          ? styles.quintileColorScale.purple[5]
                          : null
                      }
                      width={windowWidth}
                      incomeGroupShare={d.income_group_share}
                      darkerScale={styles.quintileColorScale.darker}
                    />
                    <g className="countryTooltip">
                      {hoveredCountries.length > 0 ? (
                        isHoveredCtry(hoveredCountries, d) ? (
                          isHoveredIncomeGroup(hoveredCountries, d) ? (
                            <>
                              <text
                                // textAnchor={textAnchorAlign(
                                //   countryScale(
                                //     countriesRichToPoor.indexOf(d.country)
                                //   ),
                                //   windowWidth / 2
                                // )}
                                // x={countryScale(
                                //   countriesRichToPoor.indexOf(d.country)
                                // )}
                                // y={
                                //   gdpPerson_Dist(d.gdp_share_pc) -
                                //   gdpPerson_Rad(d.gdp_share_pc) -
                                //   32
                                // }
                                // style={styles.countryLabel}
                                textAnchor={textAnchorAlign(
                                  countryScale(
                                    countriesRichToPoor.indexOf(d.country)
                                  ),
                                  windowWidth / 2
                                )}
                                x={xPosCtryAlign(
                                  countryScale(
                                    countriesRichToPoor.indexOf(d.country)
                                  ),
                                  gdpPerson_Rad(d.gdp_share_pc),
                                  windowWidth / 2
                                )}
                                y={yPosCtryAlign(
                                  gdpPerson_Dist(d.gdp_share_pc),
                                  42
                                )}
                                style={styles.countryLabel}
                              >
                                {d.country}
                              </text>
                              <text
                                textAnchor={textAnchorAlign(
                                  countryScale(
                                    countriesRichToPoor.indexOf(d.country)
                                  ),
                                  windowWidth / 2
                                )}
                                x={xPosCtryAlign(
                                  countryScale(
                                    countriesRichToPoor.indexOf(d.country)
                                  ),
                                  gdpPerson_Rad(d.gdp_share_pc),
                                  windowWidth / 2
                                )}
                                y={yPosCtryAlign(
                                  gdpPerson_Dist(d.gdp_share_pc),
                                  22
                                )}
                                style={styles.countryYear}
                              >
                                {d.year}
                              </text>
                            </>
                          ) : null
                        ) : null
                      ) : null}
                    </g>
                  </React.Fragment>
                ))}
            </g>
          ))}
          <VizComments
            supportingInfo={supportingInfo}
            gdpDist={gdpPerson_Dist}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            marginLeft={margin.left}
          />
          <g className="voronoi polygons">
            {voronoiPolygons.map((point, i) => {
              const country = countryData[i];
              return (
                <g className={"clipPathCircles"} key={quintileKey(country)}>
                  <clipPath className="clip" id={quintileKey(country)}>
                    <circle
                      cx={points[i][0]}
                      cy={points[i][1]}
                      r={gdpPerson_Rad(country.gdp_share_pc) + 36}
                    />
                  </clipPath>
                  <path
                    key={quintileKey(country)}
                    clipPath={`url(#${quintileKey(country)})`}
                    className="path"
                    d={lineGenerator(point)}
                    stroke="transparent"
                    strokeWidth="1"
                    opacity="0.5"
                    fill="transparent"
                    onMouseEnter={() => {
                      return actions.hoverCountry(country);
                    }}
                    onMouseLeave={() => {
                      return actions.unhoverCountry(country);
                    }}
                  />
                </g>
              );
            })}
          </g>
        </svg>
      </div>
    </>
  );
};

export default Dataviz;
