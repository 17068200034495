import React from "react";
import Person from "../Person/Person";

const styles = {
  innerCircle: {
    fill: "#672B7A"
  },
  outerCircle: {
    fill: "#C352EB"
  }
};

const richestPersonRadius = 100;

const RichestMiddlePerson = props => {
  return (
    <g>
      <Person
        cx={props.cx}
        cy={props.cy}
        rInner={4}
        rOuter={richestPersonRadius}
        stylesOuterCircle={styles.outerCircle}
        stylesInnerCircle={styles.innerCircle}
      />
    </g>
  );
};

export default RichestMiddlePerson;
