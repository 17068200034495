import React from "react";

// Styles
const styles = {
  comment: {
    fontSize: "16px",
    fill: "#ECEFF1",
    opacity: 1
  },
  line: {
    strokeWidth: 0.5,
    stroke: "#ECEFF1"
  }
};

const SupportInfo = props => {
  const baseLine = props.gdpDist(props.money);
  const distFromLine = props.orientation === "above" ? 10 : -22;
  const lineHeight = props.orientation === "above" ? 24 : -24;
  const text1posy = baseLine - distFromLine - lineHeight;
  const text2posy = baseLine - distFromLine;

  return (
    <g>
      <text style={styles.comment} x={props.marginLeft} y={text1posy}>
        {props.text1}
      </text>
      <text style={styles.comment} x={props.marginLeft} y={text2posy}>
        {props.text2}
      </text>
      {
        <line
          x1={props.marginLeft}
          y1={baseLine}
          x2={props.windowWidth}
          y2={baseLine}
          style={styles.line}
        />
      }
    </g>
  );
};

export default SupportInfo;
