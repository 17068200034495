import React from "react";

const styles = {
  position: "relative",
  paddingTop: "10vh"
};

const Section = props => {
  return (
    <div className={props.className} style={styles}>
      {props.children}
    </div>
  );
};

export default Section;
