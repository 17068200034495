import React from "react";

// SVG components
import PoorestPerson from "./QuintilePersons/PoorestPerson";
import LowerMiddlePerson from "./QuintilePersons/LowerMiddlePerson";
import MiddlePerson from "./QuintilePersons/MiddlePerson";
import UpperMiddlePerson from "./QuintilePersons/UpperMiddlePerson";
import RichestPerson from "./QuintilePersons/RichestPerson";

const styles = {
  line: {
    strokeWidth: 1,
    stroke: "#ECEFF1",
    opacity: 0.5
  }
};

const xPos = function(pcent, width) {
  return pcent * width;
};

const yPos = function(pcent, height) {
  const margin = 100;
  return (1 - pcent) * height + margin;
};

const QuintoUnequal = props => {
  const margin = 100;
  const adjHeight = props.height + margin;
  return (
    <svg width={props.windowWidth} height={props.height + margin}>
      <line
        x1={0}
        y1={adjHeight}
        x2={props.windowWidth}
        y2={margin}
        style={styles.line}
      />
      <RichestPerson
        cx={xPos(1, props.windowWidth)}
        cy={yPos(1, props.height)}
      />
      <UpperMiddlePerson
        cx={xPos(0.65, props.windowWidth)}
        cy={yPos(0.65, props.height)}
      />
      {/* <MiddlePerson
        cx={xPos(0.5, props.windowWidth)}
        cy={yPos(0.5, props.height)}
      /> */}
      <LowerMiddlePerson
        cx={xPos(0.4, props.windowWidth)}
        cy={yPos(0.4, props.height)}
      />
      <PoorestPerson
        cx={xPos(0.35, props.windowWidth)}
        cy={yPos(0.35, props.height)}
      />
    </svg>
  );
};

export default QuintoUnequal;
