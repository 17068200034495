import React from "react";

const Link = props => {
  return (
    <p>
      <b>
        <a href={props.link}>{props.source}</a>
      </b>
      <span>
        {props.supportText !== "" ? " - " : null}
        {props.supportText}
      </span>
    </p>
  );
};

export default Link;
