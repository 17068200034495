import React from "react";
import ContentLayout from "../component/ContentLayout";
import Section from "../component/Section";
import { useScrollBounds } from "../lib/useScrollBounds";
import ExtraSpacing from "../component/ExtraSpacing";
import WorldOpportunitiesContent from "./WorldOpportunitiesContent";

// SVGs
import Solo from "../component/Intro/Solo";
import QuintoEqual from "../component/Intro/QuintoEqual";
import QuintoUnequal from "../component/Intro/QuintoUnequal";

const styles = {
  center: {
    textAlign: "center"
  }
};

const Intro = props => {
  const [
    containerRef,
    { windowHeight, windowWidth, containerHeight }
  ] = useScrollBounds();

  return (
    <Section className="intro" ref={containerRef}>
      <ContentLayout>
        <h1 style={styles.center}>Out of Reach</h1>
        <p style={styles.center}>
          Visualizing the scale of the income gap within and between countries
        </p>
      </ContentLayout>
      <ExtraSpacing />
      <Solo windowWidth={windowWidth} height={50} style={styles} />
      <ExtraSpacing />
      <ExtraSpacing />
      <QuintoEqual windowWidth={windowWidth} height={50} />
      <ExtraSpacing />
      <ContentLayout>
        <p>
          In an ideal society, everyone gets equal access to opportunities with
          constant stream of income, education, health, nutrition and others.
          Overall, a better standard of living for everyone.
        </p>
        <p>In reality, that may not be the case. </p>
      </ContentLayout>
      <ExtraSpacing />
      <QuintoUnequal windowWidth={windowWidth} height={400} />
      <ContentLayout>
        <p>
          In a real society, someone is at hand to a huge amount of income,
          better education and opportunities while someone is out of reach of
          those. As a result, there is a huge difference in quality of life,
          experiences and well being between individuals or groups.
        </p>
      </ContentLayout>
      <ContentLayout>
        <h2>What is Inequality?</h2>
        <p>
          Inequality is the uneven distribution of opportunities or income among
          people. It is often associated with poverty but this is not always the
          case. It focuses more on the entire population rather than poverty
          alone.
        </p>
        <p>
          Inequality can be expressed in terms of opportunities, income or
          standards of living. Throughout the discussion, we will express
          inequality in terms of income.
        </p>
      </ContentLayout>
      <WorldOpportunitiesContent style={styles.worldOpportunitiesContent} />
      <ContentLayout />
    </Section>
  );
};

export default Intro;
