import React from "react";

const styles = {
  container: {
    width: "50%",
    margin: "auto",
    textAlign: "left",
    padding: "3vw 0 3vw 0"
  }
};

const ContentLayout = props => {
  return <div style={styles.container}>{props.children}</div>;
};

export default ContentLayout;
