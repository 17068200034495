import React from "react";
import Person from "../Person/Person";

const styles = {
  innerCircle: {
    fill: "#4B2A79"
  },
  outerCircle: {
    fill: "#9052E9"
  }
};

const upperMidPersonRadius = 40;

const UpperMiddlePerson = props => {
  return (
    <g>
      <Person
        cx={props.cx}
        cy={props.cy}
        rInner={4}
        rOuter={upperMidPersonRadius}
        stylesOuterCircle={styles.outerCircle}
        stylesInnerCircle={styles.innerCircle}
      />
    </g>
  );
};

export default UpperMiddlePerson;
