import React from "react";
import MiddlePerson from "./QuintilePersons/MiddlePerson";

const styles = {
  line: {
    strokeWidth: 1,
    stroke: "#ECEFF1",
    opacity: 0.5
  }
};

const placement = function(width, i) {
  const spacing = width / 6;
  return width - spacing * i;
};

const QuintoEqual = props => {
  return (
    <svg width={props.windowWidth} height={props.height}>
      <line
        x1={0}
        y1={props.height / 2}
        x2={props.windowWidth}
        y2={props.height / 2}
        style={styles.line}
      />
      {[...new Array(4)].map((d, i) => (
        <MiddlePerson
          cx={placement(props.windowWidth / 2, 4 - i)}
          cy={props.height / 2}
        />
      ))}
    </svg>
  );
};

export default QuintoEqual;
