import React from "react";
import MiddlePerson from "../Intro/QuintilePersons/MiddlePerson";

const styles = {
  position: "sticky",
  top: "25vh",
  zIndex: 0
};

const Solo = props => {
  return (
    <div style={styles}>
      <svg width={props.windowWidth} height={props.height}>
        <MiddlePerson cx={props.windowWidth / 2} cy={props.height / 2} />
      </svg>
    </div>
  );
};

export default Solo;
