import React from "react";
import textureImg from "../image/concrete-wall-2.png";

const styles = {
  imgBackground: {
    width: "100%",
    height: "100%"
    // backgroundColor: "#263237"
    // opacity: 0.5
  }
  // margin: "0"
  // backgroundColor: "#263237"
  // padding: "0 100px 0 100px"
};

const Layout = props => {
  return <div style={styles.imgBackground}>{props.children}</div>;
};

export default Layout;
