import * as d3 from "d3";
import React from "react";

const Status = props => {
  const format = d3.format(",.3r");

  const styles = {
    p: {
      color: "white",
      margin: "0.4em 0"
    },
    incomeValue: {
      position: "absolute",
      top: "-1.2em",
      margin: "0 0 10px 0",
      padding: "0 0 5px 0"
    }
  };

  return (
    <div style={{ borderTop: "2px solid rgba(255,255,255,0.5" }}>
      <h1 style={styles.incomeValue}>
        $ {format(Math.round(props.moneyAmount))}
      </h1>
      <div style={styles.p}>
        <strong>estimated income of a person</strong>
      </div>
      <div style={styles.p}>
        GDP PPP Per Capita weighted according to quintile groups
      </div>
    </div>
  );
};

export default Status;
