import React from "react";
import ContentLayout from "../component/ContentLayout";
import Section from "../component/Section";
import Link from "../component/Link";
import extSources from "../data/extSources.json";

const Footer = () => {
  return (
    <Section>
      <ContentLayout>
        <h2>Insights</h2>
        <p>
          Through the visualization, the inequalities between individuals
          between countries are more evident. You may normally earn as high as
          $194,000 a year in Luxembourg (2015) or as impoverished as those
          people who can earn as low as $157 a year in Haiti (2012). A country
          like the US, having one of the biggest income of its top 20%, is at
          par with its poorest 20% to the middle income of other countries.
        </p>
        <p>
          There is a noticeable pattern of huge income gap between richest 20%
          and upper middle 20% among all countries observed by a trench at the
          middle of the visualization.
        </p>
        <p>
          With GINI index, you can quantify how unequal the country is.
          Visualizing inequality in terms of distances, income percentage share
          and population provided us with an even better view of the extent and
          scale of the people involved and affected by inequality.
        </p>
      </ContentLayout>
      <ContentLayout>
        <h2>Methodology</h2>
        <p>
          The data was sourced from{" "}
          <a href="https://www.wider.unu.edu/database/world-income-inequality-database-wiid4">
            {" "}
            World Income Inequality Database WIID4 of UNU-WIDER
          </a>
          . We filtered observations coming from World Bank in terms of income
          (net/gross) and gathered all the latest data available per country.
          The GDP PPP PC of a country was multiplied to its total population and
          redistributed to every quintile groups based on their income share. To
          even make more sense of the numbers, we divided the GDP of each income
          group to every individual.
        </p>
      </ContentLayout>
      <ContentLayout>
        <h2>About The Project</h2>
        <p>
          This project was created as an entry to the{" "}
          <a href="http://hdr.undp.org/en/data-visualization-challenge-2019">
            2019 Human Development Data Visualization Challenge: Visualize
            Inequality
          </a>
          . The goal of this visualization is to provide a bigger picture on the
          scale of income gaps between income groups among and in between
          countries.
        </p>
      </ContentLayout>
      <ContentLayout>
        <h2>Sources and Inspiration</h2>
        {extSources.map(d => (
          <Link source={d.source} supportText={d.supportText} link={d.link} />
        ))}
      </ContentLayout>
      <ContentLayout>
        <h2>Credits</h2>
        <p>
          {"Joseph Ricafort, Peter Gassner, and everyone from "}
          <b>
            <a href="https://www.interactivethings.com/">Interactive Things</a>
          </b>
        </p>
      </ContentLayout>
    </Section>
  );
};

export default Footer;
