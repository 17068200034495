import React from "react";
import posed from "react-pose";

const Circle = posed.circle();

const incomeGroupLabel = function(incomeGroup, list) {
  return incomeGroup === "q5"
    ? list[0]
    : incomeGroup === "q4"
    ? list[1]
    : incomeGroup === "q3"
    ? list[2]
    : incomeGroup === "q2"
    ? list[3]
    : incomeGroup === "q1"
    ? list[4]
    : null;
};

const dotFill = function(incomeGroup, darkerScale) {
  return incomeGroup === "q5"
    ? darkerScale[1]
    : incomeGroup === "q4"
    ? darkerScale[2]
    : incomeGroup === "q3"
    ? darkerScale[3]
    : incomeGroup === "q2"
    ? darkerScale[4]
    : incomeGroup === "q1"
    ? darkerScale[5]
    : null;
};

const Person = props => {
  return (
    <g className={`person ${props.country} ${props.incomeGroup}`}>
      <Circle
        cy={props.y}
        cx={props.x}
        r={props.rIncome}
        opacity={props.opacityIncome}
        fill={props.incomeFill}
      />
      <Circle
        cy={props.y}
        cx={props.x}
        r={3}
        opacity={1}
        fill={dotFill(props.incomeGroup, props.darkerScale)}
      />
      <g>
        (
        <>
          <text
            textAnchor={props.x > props.width / 2 ? "end" : "start"}
            y={props.y}
            x={
              props.width / 2 < props.x
                ? props.x - props.rIncome - 10
                : props.x + props.rIncome + 10
            }
            fill={"white"}
            opacity={props.opacityLabels}
            fontWeight={"bold"}
          >
            {incomeGroupLabel(props.incomeGroup, props.incomeGroupList)}
          </text>
          <text
            textAnchor={props.x > props.width / 2 ? "end" : "start"}
            y={props.y + 18}
            x={
              props.width / 2 < props.x
                ? props.x - props.rIncome - 10
                : props.x + props.rIncome + 10
            }
            fill={"white"}
            opacity={props.opacityLabels}
          >
            ${props.income} ({Math.round(props.incomeGroupShare)}%)
          </text>
        </>
        )
      </g>
    </g>
  );
};

export default Person;
