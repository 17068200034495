import React from "react";
import ContentLayout from "../component/ContentLayout";

const WorldOpportunitiesContent = props => {
  return (
    <div style={props.style}>
      <ContentLayout>
        <h2>How Distant is the Income Gap Within and Between Countries?</h2>
        <p>
          To answer this question, let us divide the population of a country
          into 5 income groups and redistribute the total GDP (Gross Domestic
          Product) of the country to every person depending on the income share
          of their group.
        </p>
        <p>
          Assume that the new weighted GDP per capita is the received income of
          the individual. If we represent every person coming from 5 different
          income groups from 74 countries, how distant the income gap would be
          between individuals from different income levels? To what extent is
          the scale of the people being involved and affected by inequality?
        </p>
      </ContentLayout>
    </div>
  );
};

export default WorldOpportunitiesContent;
