import React from "react";
import Person from "../Person/Person";

const styles = {
  innerCircle: {
    fill: "#273B72"
  },
  outerCircle: {
    fill: "#4D73DD"
  }
};

const lowerMidPersonRadius = 15;

const LowerMiddlePerson = props => {
  return (
    <g>
      <Person
        cx={props.cx}
        cy={props.cy}
        rInner={4}
        rOuter={lowerMidPersonRadius}
        stylesOuterCircle={styles.outerCircle}
        stylesInnerCircle={styles.innerCircle}
      />
    </g>
  );
};

export default LowerMiddlePerson;
