import React from "react";
import Person from "../Person/Person";

const styles = {
  innerCircle: {
    fill: "#2E2977"
  },
  outerCircle: {
    fill: "#5B50E5"
  }
};

const midPersonRadius = 25;

const MiddlePerson = props => {
  return (
    <g>
      <Person
        cx={props.cx}
        cy={props.cy}
        rInner={4}
        rOuter={midPersonRadius}
        stylesOuterCircle={styles.outerCircle}
        stylesInnerCircle={styles.innerCircle}
      />
    </g>
  );
};

export default MiddlePerson;
