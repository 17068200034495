import React from "react";
import Person from "../Person/Person";

const styles = {
  innerCircle: {
    fill: "#244F6B"
  },
  outerCircle: {
    fill: "#499BD1"
  }
};

const poorestPersonRadius = 7;

const PoorestPerson = props => {
  return (
    <g>
      <Person
        cx={props.cx}
        cy={props.cy}
        rInner={4}
        rOuter={poorestPersonRadius}
        stylesOuterCircle={styles.outerCircle}
        stylesInnerCircle={styles.innerCircle}
      />
    </g>
  );
};

export default PoorestPerson;
