import React from "react";

export function useScrollBounds() {
  const ref = React.useRef(null);
  const [state, setState] = React.useState({
    containerVPos: 0,
    containerHeight: 0,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    scrollVPos: window.scrollY
  });

  React.useEffect(() => {
    const handleUpdate = () => {
      if (ref.current) {
        const bounds = ref.current.getBoundingClientRect();
        setState({
          containerVPos: window.innerHeight / 2 - bounds.top,
          containerHeight: bounds.height,
          windowHeight: window.innerHeight,
          windowWidth: window.innerWidth,
          scrollVPos: window.scrollX
        });
      }
    };

    handleUpdate();

    window.addEventListener("resize", handleUpdate);
    window.addEventListener("orientationchange", handleUpdate);
    window.addEventListener("scroll", handleUpdate);

    return () => {
      window.removeEventListener("resize", handleUpdate);
      window.removeEventListener("orientationchange", handleUpdate);
      window.removeEventListener("scroll", handleUpdate);
    };
  }, [setState]);

  return [ref, state];
}
