import React from "react";
import SupportInfo from "./SupportInfo/SupportInfo";

const VizComments = props => {
  return (
    <>
      {props.supportingInfo.map((d, i) => (
        <SupportInfo
          key={i}
          text1={d.info1}
          text2={d.info2}
          money={d.money}
          gdpDist={props.gdpDist}
          windowWidth={props.windowWidth}
          windowHeight={props.windowHeight}
          marginLeft={props.marginLeft}
          orientation={d.orientation}
        />
      ))}
    </>
  );
};

export default VizComments;
