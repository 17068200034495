import React from "react";
import { useImmer } from "use-immer";
import "./App.css";
import Dataviz from "./container/Dataviz";
import Intro from "./container/Intro";
import Layout from "./container/Layout";
import Footer from "./container/Footer";
import data from "./data/inequality.json";
import supportingInfo from "./data/supportingInfo.json";

// -----------------------------------------------------------------------------
// State

const initialState = (function() {
  const countryData = data.filter(
    d => d.country !== "World" && d.region !== "World"
  );

  return {
    data: data,
    countryData,
    countriesRichToPoor: countryData
      .filter(d => d.income_group === "q5")
      .sort((a, b) => b.gdp_share_pc - a.gdp_share_pc)
      .map(d => d.country),
    hoveredCountries: [],
    supportingInfo
  };
})();

// -----------------------------------------------------------------------------
// App

export default function App() {
  const [state, updateState] = useImmer(initialState);

  const actions = {
    hoverCountry: d => {
      updateState(draft => {
        draft.hoveredCountries = [d];
      });
    },
    unhoverCountry: d => {
      updateState(draft => {
        draft.hoveredCountries = state.hoveredCountries.filter(
          x => !(x.country === d.country && x.income_group === d.income_group)
        );
      });
    }
  };

  return (
    <div className="App">
      <Layout>
        <Intro state={state} />
        <Dataviz state={state} actions={actions} />
        <Footer />
      </Layout>
    </div>
  );
}
