// ----------------------------------------------------------------------------
// Helpers

export function isHovered(hoveredCountries, d) {
  return !!hoveredCountries.find(
    el => el.country === d.country && el.income_group === d.income_group
  );
}

export function isHoveredCtry(hoveredCountries, d) {
  return !!hoveredCountries.find(el => el.country === d.country);
}

export function isRelated(hoveredCountries, d) {
  return !!hoveredCountries.find(el => el.country === d.country);
}

export function quintileKey(d) {
  return `${d.country_code}-${d.income_group}`;
}

export function isHoveredIncomeGroup(hoveredCountries, d) {
  return !!hoveredCountries.find(el => el.income_group === d.income_group);
}

// Country tooltip
export function textAnchorAlign(position, reference) {
  return position > reference ? "end" : "start";
}

export function xPosCtryAlign(position, radius, reference) {
  const gap = 12;
  return position > reference
    ? position - radius - gap
    : position + radius + gap;
}

export function yPosCtryAlign(position, lineHeight) {
  return position - lineHeight;
}
