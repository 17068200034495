import React from "react";

const styles = {
  height: 180
};

const ExtraSpacing = props => {
  return <div style={styles}>{props.children}</div>;
};

export default ExtraSpacing;
