import React from "react";

const Person = props => {
  return (
    <g>
      <circle
        cx={props.cx}
        cy={props.cy}
        r={props.rOuter}
        style={props.stylesOuterCircle}
        opacity={0.85}
      />
      <circle
        cx={props.cx}
        cy={props.cy}
        r={3}
        style={props.stylesInnerCircle}
      />
    </g>
  );
};

export default Person;
